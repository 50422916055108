<template>
  <div :id="`${id}-supply-document-lines`">
    <validation-observer ref="supply-document-lines-observer" v-slot="{ valid }" slim>
      <!-- :hover="lines.touched === null" -->
      <!-- tbody-tr-class="pdf-item" doesn't work to prevent to split a row in two page. Why ?? -->
      <b-table
        id="supply-document-lines"
        ref="supply-document-lines"
        :busy="supplyDocumentLoading"
        :fields="linesDocumentFields"
        :hover="false"
        :items="lines.edited"
        :show-empty="!line"
        :class="[{ 'font-size-for-print': printMode }, 'position-relative row-align-top table-align-items-top']"
        thead-class="text-nowrap"
        small
      >
        <!-- LOADING -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <!-- EMPTY -->
        <template #empty>
          <div v-if="supplyDocumentEdition && !supplyDocumentLoading" class="text-center text-primary my-2">
            <b-button id="supply-document-new-line-add" size="md" variant="outline-primary" @click="addLine()">
              <span class="text-nowrap">
                <feather-icon class="mr-25" icon="PlusIcon" />
                {{ $t('supply_document.add_new_line') }}
              </span>
            </b-button>
          </div>
          <div v-else class="text-center my-2">
            <span class="text-nowrap">
              {{ $t('common.no_record_found') }}
            </span>
          </div>
        </template>
        <!-- TOP ROW -->
        <template v-if="supplyDocumentEdition" #bottom-row>
          <!-- add new line -->
          <b-td v-if="supplyDocumentEdition && !line"> </b-td>
          <b-td v-if="supplyDocumentEdition && !line" class="sticky-column">
            <b-overlay :show="supplyDocumentLoading">
              <b-button id="supply-document-new-line-add" block class="mt-1" size="sm" variant="outline-primary" @click="addLine()">
                <span class="text-nowrap">
                  <feather-icon class="mr-25" icon="PlusIcon" />
                  {{ $t('supply_document.add_new_line') }}
                </span>
              </b-button>
            </b-overlay>
          </b-td>
          <b-td v-if="supplyDocumentEdition && !line"> </b-td>
          <b-td v-if="supplyDocumentEdition && !line"> </b-td>
          <b-td v-if="supplyDocumentEdition && !line"> </b-td>
          <b-td v-if="supplyDocumentEdition && !line"> </b-td>
          <b-td v-if="supplyDocumentEdition && !line"> </b-td>
          <b-td v-if="supplyDocumentEdition && !line"> </b-td>

          <!-- new line -->
          <!-- actions -->
          <b-td v-if="supplyDocumentEdition && line" class="sticky-column">
            <!--save-->
            <b-button v-if="valid" id="new-line-save" class="btn-icon save-btn mt-1" size="sm" variant="outline-primary" @click.prevent="saveLine(line)">
              <feather-icon icon="SaveIcon" size="11" />
            </b-button>
            <!--cancel-->
            <b-button id="new-line-cancel" class="btn-icon mt-1" size="sm" variant="outline-secondary" @click.prevent="clearLine()">
              <feather-icon icon="SlashIcon" size="10" />
            </b-button>
            <!-- <app-data-table-actions
              id="new-line"
              table-name="supply-document-lines"
              :show-dropdown="false"
              :show-cancel="true"
              :show-save="valid"
              class="mt-1"
              @cancel="clearLine()"
              @save="saveLine(line)"
            /> -->
          </b-td>
          <b-td v-if="supplyDocumentEdition && line">
            <!-- name -->
            <b-card-text class="font-weight-bold mb-25">
              <validation-provider
                v-if="supplyDocumentEdition"
                #default="{ errors }"
                :name="$t('common.name')"
                :vid="`new-line-name-provider`"
                rules="required"
              >
                <b-form-group>
                  <b-input-group class="mt-1 task-group" size="sm">
                    <b-form-input :id="`new-line-name`" v-model="line.name" :placeholder="$t('common.name')" :state="errors[0] && false" type="text" />
                  </b-input-group>
                  <b-tooltip v-if="errors[0]" :target="`new-line-name`" placement="bottom" show variant="light">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
              <span v-else> {{ line.name }} </span>
            </b-card-text>
            <!-- decription -->
            <b-card-text>
              <validation-provider #default="{ errors }" :name="$t('common.description')" :vid="`new-line--description-provider`">
                <b-form-group>
                  <b-input-group>
                    <b-form-textarea
                      :id="`new-line--description`"
                      v-model="line.description"
                      :placeholder="$t('common.description')"
                      :plaintext="!supplyDocumentEdition"
                      class="task-group hide-scroll"
                      max-rows="100"
                      rows="4"
                      size="sm"
                    />
                  </b-input-group>
                  <b-tooltip v-if="errors[0]" :target="`new-line--description`" placement="bottom" show variant="light">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
              <!-- <div v-else v-html="line.description" /> -->
            </b-card-text>
          </b-td>
          <b-td v-if="supplyDocumentEdition && line">
            <!-- priceExcludingTaxes -->
            <validation-provider
              v-if="showLineExTaxPrice(line.lineClass)"
              #default="{ errors }"
              :name="$tc('pricing.ex_tax_price', 1)"
              rules="required|decimalOrInt"
              vid="new-line-ex-tax-price"
            >
              <b-form-group>
                <b-input-group :append="currencyAbbr" class="mt-1 input-group">
                  <b-form-input
                    id="new-line-ex-tax-price"
                    v-model="line.priceExcludingTaxes"
                    :placeholder="$tc('pricing.ex_tax_price', 1)"
                    :state="errors[0] && false"
                    number
                    type="number"
                    @input="increaseNewLineExTaxPrice()"
                  />
                </b-input-group>
                <b-tooltip v-if="errors[0]" placement="bottom" show target="new-line-ex-tax-price" variant="light">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
          </b-td>
          <b-td v-if="supplyDocumentEdition && line">
            <!-- quantity -->
            <validation-provider
              v-if="showLineQuantity(line.lineClass)"
              #default="{ errors }"
              :name="$tc('pricing.quantity', 1)"
              rules="required|integer"
              vid="new-line-quantity"
            >
              <b-form-group>
                <b-input-group class="mt-1 input-group" size="sm">
                  <b-form-input
                    id="new-line-quantity"
                    v-model="line.quantity"
                    :locale="$i18n.locale"
                    :placeholder="$tc('pricing.quantity', 1)"
                    :state="errors[0] && false"
                    min="0"
                    type="number"
                    @input="increaseNewLineExTaxPrice()"
                  ></b-form-input>
                </b-input-group>
                <b-tooltip v-if="errors[0]" placement="bottom" show target="new-line-quantity" variant="light">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
          </b-td>
          <b-td v-if="supplyDocumentEdition && line">
            <!-- totalPriceExcludingTaxes -->
            <b-input-group :append="currencyAbbr" class="input-group mt-1">
              <b-form-input
                id="new-line-total-price-excluding-taxes"
                v-model="line.totalPriceExcludingTaxes"
                :placeholder="$t('pricing.total_ex_tax_price')"
                disabled
                number
                type="number"
              />
            </b-input-group>
          </b-td>
          <b-td v-if="supplyDocumentEdition && line">
            <!-- discountRate -->
            <validation-provider v-if="line.lineClass !== 'PackageQuoteLine'" #default="{ errors }" :name="$t('pricing.discount_rate')" rules="required|percentage" vid="new-line-discount-rate">
              <b-form-group>
                <b-input-group append="%" class="mt-1 input-group" size="sm">
                  <b-form-input
                    v-if="showLineDiscountRate(line.lineClass)"
                    id="new-line-discount-rate"
                    v-model="line.discountRate"
                    :placeholder="$t('pricing.discount_rate')"
                    :state="errors[0] && false"
                    number
                    type="number"
                    @input="updateNewLineInTaxPrice()"
                  />
                </b-input-group>
                <b-tooltip v-if="errors[0]" placement="bottom" show target="new-line-discount-rate" variant="light">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
            <validation-provider v-else #default="{ errors }" :name="$t('pricing.discount_rate')" rules="required|number" vid="new-line-discount-rate">
              <b-form-group>
                <b-input-group :append="currencyAbbr" class="mt-1 input-group" size="sm">
                  <b-form-input
                    v-if="showLineDiscountRate(line.lineClass)"
                    id="new-line-discount-rate"
                    v-model="line.amountDiscount"
                    :placeholder="$t('pricing.discount_rate')"
                    :state="errors[0] && false"
                    number
                    type="number"
                    @input="updateNewLineInTaxPrice()"
                  />
                </b-input-group>
                <b-tooltip v-if="errors[0]" placement="bottom" show target="new-line-discount-rate" variant="light">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
          </b-td>
          <b-td v-if="supplyDocumentEdition && line">
            <!-- vatRate -->
            <validation-provider #default="{ errors }" :name="$t('pricing.vat_rate')" rules="required|percentage" vid="new-line-vat-rate">
              <b-form-group
                id="new-line-vat-rate-group"
                :label="$t('pricing.vat_rate')"
                class="mt-1 input-group text-nowrap"
                content-cols-lg="10"
                label-class="font-weight-bold mt-25"
                label-cols-lg="2"
                label-for="new-line-vat-rate"
                label-size="sm"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="new-line-vat-rate"
                    v-model="line.vatRate"
                    :append="showLineAppendType(line.lineClass)"
                    :placeholder="$t('pricing.vat_rate')"
                    :state="errors[0] && false"
                    number
                    type="number"
                    @input="updateNewLineInTaxPrice()"
                  />
                  <b-input-group-append>
                    <span v-if="line.lineClass === 'PackageQuoteLine'" class="input-text-span">{{ currencyAbbr }}</span>
                    <span v-else class="input-text-span">%</span>
                  </b-input-group-append>
                </b-input-group>
                <b-tooltip v-if="errors[0]" placement="bottom" show target="new-line-vat-rate" variant="light">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
            <!-- ecotax -->
            <validation-provider #default="{ errors }" :name="$tc('pricing.ecotax', 1)" rules="required|decimalOrInt" vid="new-line-ecotax">
              <b-form-group
                id="new-line-ecotax-group"
                :label="$tc('pricing.ecotax', 2)"
                class="mt-1 input-group text-nowrap"
                content-cols-lg="10"
                label-class="font-weight-bold mt-25"
                label-cols-lg="2"
                label-for="new-line-ecotax"
                label-size="sm"
              >
                <b-input-group :append="currencyAbbr" class="mt-1 input-group">
                  <b-form-input
                    id="new-line-ecotax"
                    v-model="line.ecotax"
                    :placeholder="$tc('pricing.ecotax', 1)"
                    :state="errors[0] && false"
                    number
                    type="number"
                    @input="increaseNewLineExTaxPrice()"
                  />
                </b-input-group>
                <b-tooltip v-if="errors[0]" placement="bottom" show target="new-line-ecotax" variant="light">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
          </b-td>

          <b-td v-if="supplyDocumentEdition && line" class="sticky-column">
            <!-- totalPriceIncludingTaxes -->
            <b-input-group :append="currencyAbbr" class="input-group mt-1">
              <b-form-input
                id="new-line-total-price-including-taxes"
                v-model="line.totalPriceIncludingTaxes"
                :placeholder="$t('pricing.total_in_tax_price')"
                disabled
                number
                type="number"
              />
            </b-input-group>
          </b-td>
          <!-- action -->
          <!-- <b-td v-if="supplyDocumentEdition && line" align="right">
            <app-data-table-actions
              id="new-line"
              table-name="supply-document-lines"
              :show-dropdown="false"
              :show-cancel="true"
              :show-save="valid"
              class="mt-1"
              @cancel="clearLine()"
              @save="saveLine(line)"
            />
          </b-td> -->
        </template>
        <!-- CELL -->
        <!-- status -->
        <!-- <template #cell(status)="data">
          <b-badge
            :id="`line-${data.index}-status`"
            :variant="`${resolveSupplyLineStatusVariant(data.item.status)}`"
            :class="[supplyDocumentEdition ? 'status-badge-edit' : 'status-badge']"
          >
            &nbsp;
          </b-badge>
          <b-tooltip :target="`line-${data.index}-status`" placement="bottom" variant="light">
            <small>{{ data.item.status }}</small>
          </b-tooltip>
        </template> -->
        <!-- taskDescription -->
        <template #cell(taskDescription)="data">
          <!-- cancellation condition -->
          <div v-if="showCancellationConditions && data.item.conditions.ifClause" class="font-weight-bold mb-25">
            <span :class="[printMode ? 'task-group-print' : 'task-group']">
              {{ `${$t('cancellation_policy.title', 1)} ${data.item.conditions.ifClause} :` }}
            </span>
          </div>
          <!-- name -->
          <div class="font-weight-bold mb-25">
            <validation-provider
              v-if="supplyDocumentEdition"
              #default="{ errors }"
              :name="$t('common.name')"
              :vid="`line-${data.index}-name-provider`"
              rules="required"
            >
              <b-form-group :class="[printMode ? 'task-group-print' : 'task-group', 'mt-1']">
                <b-form-input
                  :id="`line-${data.index}-name`"
                  v-model="data.item.name"
                  :disabled="lines.touched !== null && lines.touched !== data.index ? true : false"
                  :placeholder="$t('common.name')"
                  :state="errors[0] && false"
                  size="sm"
                  type="text"
                  @click="lines.touched = data.index"
                />
                <b-tooltip v-if="errors[0]" :target="`line-${data.index}-name`" placement="bottom" show variant="light">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
            <span v-else :class="[printMode ? 'task-group-print' : 'task-group']"> {{ data.item.name }} </span>
          </div>
          <!-- decription -->
          <div>
            <validation-provider
              v-if="supplyDocumentEdition"
              #default="{ errors }"
              :name="$t('common.description')"
              :vid="`line-${data.index}-description-provider`"
              rules=""
            >
              <b-form-group>
                <b-form-textarea
                  :id="`line-${data.index}-description`"
                  v-model="data.item.description"
                  :disabled="lines.touched !== null && lines.touched !== data.index ? true : false"
                  :max-rows="printMode ? '100' : '4'"
                  :placeholder="$t('common.description')"
                  class="task-group hide-scroll"
                  rows="4"
                  size="sm"
                  @click="lines.touched = data.index"
                />
                <b-tooltip v-if="errors[0]" :target="`line-${data.index}-description`" placement="bottom" show variant="light">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-else :class="[printMode ? 'task-group-print' : 'task-group']" style="white-space: pre-line" v-html="data.item.description"></span>
          </div>
        </template>
        <!-- priceExcludingTaxes -->
        <template #cell(priceExcludingTaxes)="data">
          <validation-provider
            v-if="supplyDocumentEdition && showLineExTaxPrice(data.item.lineClass)"
            #default="{ errors }"
            :name="$tc('pricing.ex_tax_price', 1)"
            :vid="`line-${data.index}-ex-tax-price-provider`"
            rules="required|decimalOrInt"
          >
            <b-form-group>
              <b-input-group :id="`line-${data.index}-ex-tax-price-input-group`" :append="currencyAbbr" size="sm" class="mt-1 input-group">
                <b-form-input
                  :id="`line-${data.index}-ex-tax-price`"
                  v-model.number="data.item.priceExcludingTaxes"
                  :disabled="lines.touched !== null && lines.touched !== data.index"
                  :placeholder="$tc('pricing.ex_tax_price', 1)"
                  :state="errors[0] && false"
                  number
                  step="0.01"
                  size="sm"
                  type="number"
                  @click="lines.touched = data.index"
                  @input="updateLineExTaxPrice(data.index)"
                />
              </b-input-group>
              <b-tooltip v-if="errors[0]" :target="`line-${data.index}-ex-tax-price-input-group`" placement="bottom" show variant="light">
                <small class="text-danger">{{ errors[0] }}</small>
              </b-tooltip>
            </b-form-group>
          </validation-provider>
          <span v-if="!supplyDocumentEdition && showLineExTaxPrice(data.item.lineClass)">{{ data.item.priceExcludingTaxes | priceFormat }}</span>
        </template>
        <!-- quantity -->
        <template #cell(quantity)="data">
          <!-- :rules="noPriceIncrease ? `required|integer|max_value:${maxLineQuantiy(data.index)}` : 'required|integer'" -->

          <validation-provider
            v-if="supplyDocumentEdition && showLineQuantity(data.item.lineClass)"
            #default="{ errors }"
            :name="$tc('pricing.quantity', 1)"
            :vid="`line-${data.index}-quantity-provider`"
            rules="required|integer"
          >
            <b-form-group>
              <b-input-group :id="`line-${data.index}-quantity-input-group`" class="mt-1 input-group" size="sm">
                <b-form-input
                  :id="`line-${data.index}-quantity`"
                  v-model="data.item.quantity"
                  :locale="$i18n.locale"
                  :placeholder="$tc('pricing.quantity', 1)"
                  :state="errors[0] && false"
                  min="0"
                  :disabled="lines.touched !== null && lines.touched !== data.index"
                  size="sm"
                  type="number"
                  @change="lines.touched = data.index"
                  @input="updateLineExTaxPrice(data.index)"
                ></b-form-input>
              </b-input-group>
              <b-tooltip v-if="errors[0]" :target="`line-${data.index}-quantity-input-group`" placement="bottom" show variant="light">
                <small class="text-danger">{{ errors[0] }}</small>
              </b-tooltip>
            </b-form-group>
          </validation-provider>
          <span v-if="!supplyDocumentEdition && showLineQuantity(data.item.lineClass)">{{
            data.item.lineClass === 'FuelQuoteLine' ? data.item.fuelQuantity : data.item.quantity
          }}</span>
        </template>
        <!-- totalPriceExcludingTaxes -->
        <template #cell(totalPriceExcludingTaxes)="data">
          <b-input-group v-if="supplyDocumentEdition" :append="currencyAbbr" size="sm" class="input-group mt-1">
            <b-form-input
              :id="`line-${data.index}-total-price-excluding-taxes`"
              v-model="data.item.totalPriceExcludingTaxes"
              :placeholder="$t('pricing.total_ex_tax_price')"
              disabled
              number
              type="number"
            />
          </b-input-group>
          <span v-else>{{ data.item.totalPriceExcludingTaxes | priceFormat }}</span>
        </template>
        <!-- taxes -->
        <template #cell(taxes)="data">
          <!-- vatAmount -->
          <div v-if="data.item.lineClass === 'PackageQuoteLine'" :class="[printMode ? 'taxes-margin-print' : 'mb-25']">
            <validation-provider
              v-if="supplyDocumentEdition"
              #default="{ errors }"
              :name="$t('pricing.vat_amount')"
              :vid="`line-${data.index}-vat-amount-provider`"
              rules="required"
            >
              <b-form-group
                :id="`line-${data.index}-vat-amount-group`"
                label-cols="12"
                label-size="sm"
                content-cols="12"
                label-class="font-weight-bold mt-0 pb-0"
                :label="$t('pricing.vat_amount')"
                :label-for="`line-${data.index}-vat-amount`"
                class="mt-0 mr-1 input-group text-nowrap"
              >
                <b-input-group :id="`line-${data.index}-vat-amount-input-group`" :append="showLineAppendType(data.item.lineClass)" size="sm">
                  <b-form-input
                    :id="`line-${data.index}-vat-amount`"
                    v-model="data.item.amountOfTaxes"
                    :placeholder="$t('pricing.vat_amount')"
                    :state="errors[0] && false"
                    type="number"
                    number
                    :disabled="lines.touched !== null && lines.touched !== data.index"
                    @input="updateLineInTaxPrice(data.index)"
                    @click="lines.touched = data.index"
                  />
                </b-input-group>
                <b-tooltip v-if="errors[0]" show :target="`line-${data.index}-vat-amount-input-group`" placement="bottom" variant="light">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
            <div v-else>
              <label :for="`line-${data.index}-vat-amount`" :class="[{ 'table-items-print font-size-for-print': printMode }, 'font-weight-bold mr-25']">
                {{ `${$t('pricing.vat_amount')} :` }}
              </label>
              <span>{{ data.item.amountOfTaxes | priceFormat }} </span>
            </div>
          </div>
          <!-- vatRate -->
          <div v-else :class="[printMode ? 'taxes-margin-print' : 'mb-25']">
            <validation-provider
              v-if="supplyDocumentEdition"
              #default="{ errors }"
              :name="$t('pricing.vat_rate')"
              :vid="`line-${data.index}-vat-rate-provider`"
              rules="required|percentage"
            >
              <b-form-group
                :id="`line-${data.index}-vat-rate-group`"
                label-cols="12"
                label-size="sm"
                content-cols="12"
                label-class="font-weight-bold mt-0 pb-0"
                :label="$t('pricing.vat_rate')"
                :label-for="`line-${data.index}-vat-rate`"
                class="mt-0 mr-1 input-group text-nowrap"
              >
                <b-input-group :id="`line-${data.index}-vat-rate-input-group`" :append="showLineAppendType(data.item.lineClass)" size="sm">
                  <!-- :disabled="(lines.touched !== null && lines.touched !== data.index) || noPriceIncrease ? true : false" -->

                  <b-form-input
                    :id="`line-${data.index}-vat-rate`"
                    v-model="data.item.vatRate"
                    :disabled="lines.touched !== null && lines.touched !== data.index"
                    :placeholder="$t('pricing.vat_rate')"
                    :state="errors[0] && false"
                    number
                    type="number"
                    @click="lines.touched = data.index"
                    @input="updateLineInTaxPrice(data.index)"
                  />
                </b-input-group>
                <b-tooltip v-if="errors[0]" :target="`line-${data.index}-vat-rate-input-group`" placement="bottom" show variant="light">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
            <div v-else>
              <label :class="[{ 'table-items-print font-size-for-print': printMode }, 'font-weight-bold mr-25']" :for="`line-${data.index}-vat-rate`">
                {{ `${$t('pricing.vat_rate')} :` }}
              </label>
              <span>{{ data.item.vatRate | percentFormat }} </span>
            </div>
          </div>
          <!-- ecotax -->
          <div>
            <validation-provider
              v-if="supplyDocumentEdition"
              #default="{ errors }"
              :name="$tc('pricing.ecotax', 1)"
              :vid="`line-${data.index}-ecotax-provider`"
              rules="required|decimalOrInt"
            >
              <b-form-group
                :id="`line-${data.index}-ecotax-group`"
                label-cols="12"
                label-size="sm"
                content-cols="12"
                label-class="font-weight-bold mt-0 pb-0"
                :label="$tc('pricing.ecotax', 2)"
                :label-for="`line-${data.index}-ecotax`"
                class="mt-0 mr-1 input-group text-nowrap"
              >
                <b-input-group :id="`line-${data.index}-ecotax-input-group`" :append="currencyAbbr" size="sm">
                  <b-form-input
                    :id="`line-${data.index}-ecotax`"
                    v-model="data.item.ecotax"
                    :disabled="(lines.touched !== null && lines.touched !== data.index)"
                    :placeholder="$tc('pricing.ecotax', 1)"
                    :state="errors[0] && false"
                    number
                    type="number"
                    @click="lines.touched = data.index"
                    @input="updateLineExTaxPrice(data.index)"
                  />
                </b-input-group>
                <b-tooltip v-if="errors[0]" :target="`line-${data.index}-ecotax-input-group`" placement="bottom" show variant="light">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
            <div v-else>
              <label :class="[{ 'table-items-print font-size-for-print': printMode }, 'font-weight-bold mr-25']" :for="`line-${data.index}-ecotax`">
                {{ $tc('pricing.ecotax', 2) }} :
              </label>
              <span>{{ data.item.ecotax | priceFormat }}</span>
            </div>
          </div>
        </template>
        <!-- discountRate -->
        <template #cell(discountRate)="data">
          <validation-provider
            v-if="supplyDocumentEdition && showLineDiscountRate(data.item.lineClass) && data.item.lineClass !== 'PackageQuoteLine'"
            #default="{ errors }"
            :name="$t('pricing.discount_rate')"
            :vid="`line-${data.index}-discount-rate-provider`"
            rules="required|percentage"
          >
            <b-form-group>
              <b-input-group :id="`line-${data.index}-discount-rate-input-group`" append="%" class="mt-1 input-group" size="sm">
                <b-form-input
                  :id="`line-${data.index}-discount-rate`"
                  v-model="data.item.discountRate"
                  :disabled="(lines.touched !== null && lines.touched !== data.index)"
                  :placeholder="$t('pricing.discount_rate')"
                  :state="errors[0] && false"
                  number
                  type="number"
                  @click="lines.touched = data.index"
                  @input="updateLineInTaxPrice(data.index)"
                />
              </b-input-group>
              <b-tooltip v-if="errors[0]" :target="`line-${data.index}-discount-rate-input-group`" placement="bottom" show variant="light">
                <small class="text-danger">{{ errors[0] }}</small>
              </b-tooltip>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-if="supplyDocumentEdition && showLineDiscountRate(data.item.lineClass) && data.item.lineClass === 'PackageQuoteLine'"
            #default="{ errors }"
            :name="$t('pricing.discount_rate')"
            :vid="`line-${data.index}-discount-rate-provider`"
            rules="required"
          >
            <b-form-group>
              <b-input-group :id="`line-${data.index}-discount-rate-input-group`" :append="currencyAbbr" class="mt-1 input-group" size="sm">
                <b-form-input
                  :id="`line-${data.index}-discount-rate`"
                  v-model="data.item.amountDiscount"
                  :disabled="(lines.touched !== null && lines.touched !== data.index)"
                  :placeholder="$t('pricing.discount_rate')"
                  :state="errors[0] && false"
                  type="number"
                  number
                  @click="lines.touched = data.index"
                  @input="updateLineInTaxPrice(data.index)"
                />
              </b-input-group>
              <b-tooltip v-if="errors[0]" :target="`line-${data.index}-discount-rate-input-group`" placement="bottom" show variant="light">
                <small class="text-danger">{{ errors[0] }}</small>
              </b-tooltip>
            </b-form-group>
          </validation-provider>
          <span v-if="!supplyDocumentEdition && data.item.lineClass !== 'PackageQuoteLine'">{{ data.item.discountRate }} %</span>
          <span v-if="!supplyDocumentEdition && data.item.lineClass === 'PackageQuoteLine'">{{ data.item.amountDiscount }} {{ currencyAbbr }}</span>
        </template>
        <!-- totalPriceIncludingTaxes -->
        <template #cell(totalPriceIncludingTaxes)="data">
          <b-input-group v-if="supplyDocumentEdition" :append="currencyAbbr" class="input-group mt-1" size="sm">
            <b-form-input
              :id="`line-${data.index}-total-price-including-taxes`"
              v-model="data.item.totalPriceIncludingTaxes"
              :placeholder="$t('pricing.total_in_tax_price')"
              disabled
              number
              type="number"
            />
          </b-input-group>
          <span v-else>{{ data.item.totalPriceIncludingTaxes | priceFormat }}</span>
        </template>
        <!-- action -->

        <template #cell(actions)="data">
          <!-- <app-data-table-actions
            :id="toString(data.index)"
            table-name="supply-document-lines"
            :show-show="false"
            :show-duplicate="lines.touched !== data.index && !line && supplyDocumentEdition && !noPriceIncrease ? true : false"
            :show-edit="false"
            :show-delete="lines.touched !== data.index && !line && supplyDocumentEdition ? true : false"
            :show-add="lines.touched === null && lines.touched !== data.index && !line && supplyDocumentEdition && !noPriceIncrease ? true : false"
            :show-dropdown="lines.touched === null && lines.touched !== data.index && !line && supplyDocumentEdition ? true : false"
            :show-cancel="lines.touched === data.index"
            :show-save="lines.touched === data.index && valid"
            class="mt-1"
            @cancel="clearLine()"
            @save="saveLine(data.item)"
            @duplicate="duplicateLine(data.item)"
            @delete="deleteLine(data.index)"
            @add="addLine()"
          /> -->

          <!--save-->
          <div>
            <b-button
              v-if="lines.touched !== data.index && !line && supplyDocumentEdition ? true : false"
              :id="`supply-document-lines-line-${data.index}-action-delete`"
              class="btn-icon mt-1"
              size="sm"
              variant="outline-danger"
              @click.prevent="deleteLine(data.index)"
            >
              <feather-icon icon="TrashIcon" size="11" />
            </b-button>
            <!--save-->
            <b-button
              v-if="lines.touched === data.index && valid"
              :id="`supply-document-lines-line-${data.index}-action-save`"
              class="btn-icon save-btn mt-1"
              size="sm"
              variant="outline-primary"
              @click.prevent="saveLine(data.item)"
            >
              <feather-icon icon="SaveIcon" size="11" />
            </b-button>
            <!--cancel-->
            <b-button
              v-if="lines.touched === data.index"
              :id="`supply-document-lines-line-${data.index}-action-cancel`"
              class="btn-icon mt-1"
              size="sm"
              variant="outline-secondary"
              @click.prevent="clearLine()"
            >
              <feather-icon icon="SlashIcon" size="10" />
            </b-button>
            <!-- <feather-icon
            v-if="lines.touched !== data.index && !line && supplyDocumentEdition ? true : false"
            :id="`supply-document-lines-line-${data.index}-action-delete`"
            color="#ee7877"
            icon="TrashIcon"
            size="16"
            class="mt-2"
            @click.prevent="deleteLine(data.index)"
          /> -->
            <!-- <feather-icon
            v-if="lines.touched === data.index && valid"
            :id="`supply-document-lines-line-${data.index}-action-save`"
            color="#e1ca93"
            icon="SaveIcon"
            size="16"
            class="mt-2"
            @click.prevent="saveLine(data.item)"
          /> -->
            <!-- <feather-icon
            v-if="lines.touched === data.index"
            :id="`supply-document-lines-line-${data.index}-action-cancel`"
            icon="SlashIcon"
            size="16"
            class="mt-2"
            @click.prevent="clearLine()"
          /> -->
          </div>
        </template>
      </b-table>
    </validation-observer>
  </div>
</template>

<script>
import { round } from 'lodash'

import calculation from '@/mixins/calculation'
import getCurrency from '@/helpers/getCurrency'

export default {
  name: 'SupplyDocumentTable',

  mixins: [calculation],

  props: {
    id: {
      type: String,
      default: '',
    },

    supplyDocumentEdition: {
      type: Boolean,
      default: false,
    },

    supplyDocumentLoading: {
      type: Boolean,
      default: false,
    },

    supplyDocumentLines: {
      type: Array,
      default: () => [],
    },

    printMode: {
      type: Boolean,
      default: false,
    },

    // noPriceIncrease: {
    //   type: Boolean,
    //   default: false,
    // },

    showCancellationConditions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currencyAbbr: undefined,
      lines: { edited: [], old: [], touched: null },
      line: null,
    }
  },

  computed: {
    linesDocumentFields() {
      const fields = [
        {
          key: 'actions',
          label: '',
          thClass: 'align-text-top',
          tdClass: 'align-text-top',
          stickyColumn: true,
          isRowHeader: true,
          variant: 'light',
        },
        {
          key: 'taskDescription',
          label: this.$t('supply_document.task_description'),
          thClass: this.printMode ? 'pr-0' : '',
          tdClass: this.printMode ? 'pr-0' : '',
        },
        { key: 'priceExcludingTaxes', label: `${this.$tc('pricing.unit_price', 2)} ${this.$tc('pricing.ex_tax_price', 2)}` },
        { key: 'quantity', label: this.$tc('pricing.quantity', 2) },
        {
          key: 'totalPriceExcludingTaxes',
          label: this.$tc('pricing.total_ex_tax_price', 2),
        },
        { key: 'discountRate', label: this.$t('pricing.discount_rate') },
        { key: 'taxes', label: this.$tc('pricing.tax', 2) },
        {
          key: 'totalPriceIncludingTaxes',
          label: this.$tc('pricing.total_in_tax_price', 2),
        },
      ]

      // if (this.supplyDocumentEdition) {
      //   fields.push({
      //     key: 'actions',
      //     label: this.$tc('action.title', 2),
      //     thClass: 'text-right',
      //     tdClass: 'text-right',
      //     stickyColumn: true,
      //   })
      // }

      if (this.supplyDocumentEdition) {
        fields.unshift({
          key: 'actions',
          label: '',
          thClass: 'align-text-top',
          tdClass: 'align-text-top',
          stickyColumn: true,
          isRowHeader: true,
          variant: 'light',
        })
      }

      // if (!this.printMode) {
      //   fields.unshift({ key: 'status', label: '' })
      // }

      return fields
    },

    totalLines() {
      return this.lines.edited ? this.lines.edited.length : 0
    },
  },

  watch: {
    supplyDocumentLines: {
      handler() {
        this.getLines()
      },
      deep: true,
    },

    'lines.touched': {
      handler(value) {
        value !== null ? this.$emit('linesTouched', true) : this.$emit('linesTouched', false)
      },
    },
  },

  created() {
    this.currencyAbbr = getCurrency()
  },

  mounted() {
    this.getLines()
  },

  methods: {
    getLines() {
      this.lines.edited = this._cloneDeep(this.supplyDocumentLines)
      this.lines.old = this._cloneDeep(this.lines.edited)
    },

    saveLine(line) {
      if (!line.id) this.lines.edited.push(line)
      this.$emit('updateLines', this.lines.edited)
      this.clearLine()
    },

    addLine() {
      this.lines.touched = this.totalLines + 1
      this.line = {
        date: null,
        description: null,
        discountRate: 0,
        amountDiscount: 0,
        ecotax: 0,
        id: null,
        name: '',
        priceExcludingTaxes: 0,
        priceIncludingTaxes: 0,
        quantity: 0,
        totalPriceExcludingTaxes: 0,
        totalPriceIncludingTaxes: 0,
        variant: [],
        variantPrice: {
          id: null,
        },
        vatRate: 0,
        conditions: [],
      }
    },

    // duplicateLine(lineToCopy) {
    //   this.line = this._cloneDeep(lineToCopy)
    //   this.line.id = null
    //   this.line.name = ''
    // },

    deleteLine(index) {
      this.lines.edited.splice(index, 1)
      this.$emit('updateLines', this.lines.edited)
    },

    clearLine() {
      this.line = null
      this.lines.touched = null
      this.lines.edited = this._cloneDeep(this.lines.old)
      this.$refs['supply-document-lines-observer'].reset()
    },

    increaseNewLineExTaxPrice() {
      this.line.totalPriceExcludingTaxes = round(this.line.priceExcludingTaxes * this.line.quantity, 2)
      this.updateNewLineInTaxPrice()
    },

    updateNewLineInTaxPrice() {
      if (this.line.lineClass !== 'PackageQuoteLine') {
        this.line.totalPriceIncludingTaxes = this.percentageDecrease(
          this.line.totalPriceExcludingTaxes,
          this.line.discountRate ? parseFloat(this.line.discountRate) : 0,
          2,
        )
      } else {
        const discount = this.line.amountDiscount ? parseFloat(this.line.amountDiscount) : 0
        this.line.totalPriceIncludingTaxes = this.line.totalPriceExcludingTaxes - discount
      }

      this.line.totalPriceIncludingTaxes = this.percentageIncrease(this.line.totalPriceIncludingTaxes, this.line.vatRate ? parseFloat(this.line.vatRate) : 0, 2)

      this.line.totalPriceIncludingTaxes += this.line.ecotax ? parseFloat(this.line.ecotax) * this.line.quantity : 0

      this.line.totalPriceIncludingTaxes = round(this.line.totalPriceIncludingTaxes, 2)
    },

    updateLineExTaxPrice(index) {
      this.lines.edited[index].totalPriceExcludingTaxes = round(this.lines.edited[index].priceExcludingTaxes * this.lines.edited[index].quantity, 2)
      this.updateLineInTaxPrice(index)
    },

    updateLineInTaxPrice(index) {
      if (this.lines.edited[index].lineClass !== 'PackageQuoteLine') {
        this.lines.edited[index].totalPriceIncludingTaxes = this.percentageDecrease(
          this.lines.edited[index].totalPriceExcludingTaxes,
          this.lines.edited[index].discountRate ? parseFloat(this.lines.edited[index].discountRate) : 0,
          2,
        )
      } else {
        const discount = this.lines.edited[index].amountDiscount ? parseFloat(this.lines.edited[index].amountDiscount) : 0
        this.lines.edited[index].totalPriceIncludingTaxes = this.lines.edited[index].totalPriceExcludingTaxes - discount
      }

      if (this.lines.edited[index].lineClass === 'PackageQuoteLine') {
        this.lines.edited[index].totalPriceIncludingTaxes += this.lines.edited[index].amountOfTaxes ? parseFloat(this.lines.edited[index].amountOfTaxes) : 0
      } else {
        this.lines.edited[index].totalPriceIncludingTaxes = this.percentageIncrease(
          this.lines.edited[index].totalPriceIncludingTaxes,
          this.lines.edited[index].vatRate ? parseFloat(this.lines.edited[index].vatRate) : 0,
          2,
        )
      }

      this.lines.edited[index].totalPriceIncludingTaxes += this.lines.edited[index].ecotax
        ? parseFloat(this.lines.edited[index].ecotax) * this.lines.edited[index].quantity
        : 0

      this.lines.edited[index].totalPriceIncludingTaxes = round(this.lines.edited[index].totalPriceIncludingTaxes, 2)
    },

    // "QUOTE_LINE": "QuoteLine",
    // "PACKAGE_LINE": "PackageQuoteLine",
    // "HANGAR_LINE": "HangarQuoteLine",
    // "HELICOPTER_LINE": "HelicopterQuoteLine"
    // maxLineQuantiy(index) {
    //   return this.lines.old[index].quantity.toString()
    // },
    // TODO: this mmethod is useless, delete it?
    showLineExTaxPrice(lineClass) {
      // if (lineClass === 'HelicopterQuoteLine') return false
      return true
    },
    showLineQuantity(lineClass) {
      return lineClass !== 'HelicopterQuoteLine'
    },
    // TODO: this mmethod is useless, delete it?
    showLineDiscountRate(lineClass) {
      // if (lineClass === 'PackageQuoteLine') return false
      return true
    },
    showLineAppendType(lineClass) {
      if (lineClass === 'PackageQuoteLine') return this.currencyAbbr
      return '%'
    },
  },
}
</script>

<style lang="scss">
.save-btn {
  box-shadow: 1px 1px 20px 1px $primary !important;
  &:hover {
    box-shadow: none !important;
  }
}
.input-group {
  min-width: 150px;
}
// .input-group-percentage {
//   min-width: 75px;
// }
.task-group {
  width: 200px;
}
.task-group-print {
  width: 200px;
  margin-right: -3rem !important;
}
.form-control:disabled {
  background-color: white !important;
}
.b-form-spinbutton.disabled {
  background-color: white !important;
}
.table-items-print {
  font-size: 1rem;
}
.taxes-margin-print {
  margin-bottom: 0rem !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
}
// .status-badge {
//   height: 14px;
//   width: 5px;
//   padding: 2px 0 0 0;
// }
// .status-badge-edit {
//   height: 33px;
//   width: 5px;
//   padding: 0;
//   margin-top: 1.2rem;
// }
.hide-scroll::-webkit-scrollbar {
  display: none;
}

.sticky-column {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  background: white;
  color: white;
  z-index: 1;
}
.font-size-for-print {
  .table thead th {
    font-size: 0.65rem !important;
  }
  font-size: 0.5rem !important;
}
</style>
