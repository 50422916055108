<template>
  <div :id="`${id}-supply-document`">
    <div
      :class="[
        { 'supply-status supply-cancelled': supply.cancelled && !headerOnly && !edition },
        { 'supply-status supply-refused': supply.refused && !headerOnly },
      ]"
    >
      <section slot="pdf-content">
        <b-overlay :show="loading" :spinner-small="false">
          <b-card v-if="supply" class="invoice-preview-card" no-body>
            <section class="pdf-item">
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                  <!-- Header: Left Content -->
                  <div v-if="supply.organization" class="mr-xl-1 mr-0">
                    <!-- Logo -->
                    <div class="mb-1">
                      <b-img
                        v-if="supply.organization.logoFileUrl"
                        :alt="`supply-document-logo-organization-id-${supply.organization.id}`"
                        :src="supply.organization.logoFileUrl || altOrganizationLogo"
                        class="logo-dimension"
                        rounded
                        crossorigin="*"
                        @error="imageLoadOnError"
                      />
                      <div v-else class="logo-dimension" />
                    </div>
                    <!-- Organisation adress -->
                    <div v-if="supply.organization.defaultAddress && !headerOnly" id="seller-address">
                      <p class="card-text mb-25 font-weight-bold">
                        {{ supply.organization.legalName || '' }}
                      </p>
                      <p class="card-text mb-25">
                        {{ supply.organization.defaultAddress.address || '' }}
                      </p>
                      <p class="card-text mb-25">
                        {{ supply.organization.defaultAddress.city || '' }},&nbsp;
                        {{ supply.organization.defaultAddress.postalCode || '' }},&nbsp;
                        {{ supply.organization.defaultAddress.countryName || '' }}
                      </p>
                      <p class="card-text mb-25">
                        {{ supply.organization.defaultAddress.complementaryAddress || '' }}
                      </p>
                      <p class="card-text mb-25">
                        {{ supply.organization.accountingContactFirstName || '' }}
                        {{ supply.organization.accountingContactLastName || '' }}
                      </p>
                      <p class="card-text mb-25">
                        {{ supply.organization.accountingContactEmail || '' }}
                      </p>
                      <p v-if="supply.client.accountingContactPhone" class="card-text mb-0">
                        {{ $t('common.phone_abr') }} :
                        {{ supply.client.accountingContactPhone }}
                      </p>
                    </div>
                  </div>

                  <!-- Header: Right Content -->
                  <div class="ml-xl-1 ml-0">
                    <!-- ID -->
                    <h4 class="invoice-title">
                      {{ title }}
                      <span class="invoice-number"> #{{ supply.reference }} </span>
                    </h4>
                    <div v-if="!headerOnly && supply.createdAt" class="invoice-date-wrapper">
                      <p class="invoice-date-title">{{ $t('common.date_issued') }}:</p>
                      <!-- createdAt -->
                      <p class="invoice-date text-nowrap">
                        {{
                          new Date(supply.createdAt).toLocaleDateString($i18n.locale, {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            weekday: 'short',
                          })
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr :class="['invoice-spacing', { 'mt-n3 ': headerOnly }]" />

              <!-- Invoice Client Details -->
              <b-card-body v-if="!headerOnly" class="invoice-padding pt-0">
                <b-row class="invoice-spacing">
                  <!-- Billing address-->
                  <b-col class="p-0" cols="12" xl="6">
                    <div class="mr-xl-1 mr-0">
                      <h6 class="mb-2">{{ $t('supply_document.address.billing') }}</h6>
                      <div v-if="supply && supply.client && supply.client.defaultAddress">
                        <h6 class="mb-25">
                          {{ supply.client.legalName }}
                        </h6>
                        <p class="card-text mb-25">
                          {{ supply.client.defaultAddress.address }}
                        </p>
                        <p class="card-text mb-25">
                          {{ supply.client.defaultAddress.city }},&nbsp; {{ supply.client.defaultAddress.postalCode }},&nbsp;
                          {{ supply.client.defaultAddress.countryName }}
                        </p>
                        <p class="card-text mb-25">
                          {{ supply.client.defaultAddress.complementaryAddress }}
                        </p>
                        <p class="card-text mb-25">
                          {{ supply.client.accountingContactFirstName }}
                          {{ supply.client.accountingContactLastName }}
                        </p>
                        <p class="card-text mb-25">
                          {{ supply.client.accountingContactEmail || '' }}
                        </p>
                        <p v-if="supply.client.accountingContactPhone" class="card-text mb-0">
                          {{ $t('common.phone_abr') }} :
                          {{ supply.client.accountingContactPhone }}
                        </p>
                        <!-- Customer Vat number -->
                        <p v-if="invoiceComposition.customer_vat_number" class="card-text mt-2 mb-0">
                          {{ invoiceComposition.customer_vat_number.title }}&nbsp;:
                          {{ invoiceComposition.customer_vat_number.value }}
                        </p>
                        <!-- Customer AOC Number -->
                        <p v-if="invoiceComposition.AOC" class="card-text mb-25">
                          {{ invoiceComposition.AOC.title }}&nbsp;:
                          {{ invoiceComposition.AOC.value }}
                        </p>
                      </div>
                    </div>
                  </b-col>

                  <!-- Invoice configuration informations-->
                  <b-col
                    v-if="!emptyObject(invoiceComposition)"
                    class="p-0 mt-xl-0 mt-2 d-flex row-xl text-xl-right justify-content-xl-end"
                    cols="12"
                    xl="6"
                  >
                    <div class="ml-xl-1 ml-0">
                      <h6 class="mb-2">{{ $t('supply_document.informations') }}</h6>
                      <div class="invoice-total-wrapper">
                        <!-- Aircraft Type -->
                        <div v-if="invoiceComposition.aircraft_type" class="invoice-total-item">
                          <p class="invoice-total-title">{{ invoiceComposition.aircraft_type.title }}&nbsp;:</p>
                          <p class="invoice-total-amount">
                            <span> {{ invoiceComposition.aircraft_type.value }}</span>
                          </p>
                        </div>
                        <!-- Aircraft Model -->
                        <div v-if="invoiceComposition.aircraft_model" class="invoice-total-item">
                          <p class="invoice-total-title">{{ invoiceComposition.aircraft_model.title }}&nbsp;:</p>
                          <p class="invoice-total-amount">
                            <span> {{ invoiceComposition.aircraft_model.value }}</span>
                          </p>
                        </div>
                        <!-- Registration / CallSign -->
                        <div
                          v-if="invoiceComposition.call_sign || invoiceComposition.immatriculation"
                          class="invoice-total-item"
                        >
                          <p class="invoice-total-title">
                            <span v-if="invoiceComposition.immatriculation">{{
                              invoiceComposition.immatriculation.title
                            }}</span>
                            <span v-if="invoiceComposition.call_sign && invoiceComposition.immatriculation"> / </span>
                            <span v-if="invoiceComposition.call_sign">{{ invoiceComposition.call_sign.title }}</span>&nbsp;:
                          </p>
                          <p class="invoice-total-amount">
                            <span v-if="invoiceComposition.immatriculation">{{
                              invoiceComposition.immatriculation.value
                            }}</span>
                            <span v-if="invoiceComposition.call_sign && invoiceComposition.immatriculation"> / </span>
                            <span v-if="invoiceComposition.call_sign">{{ invoiceComposition.call_sign.value }}</span>
                          </p>
                        </div>
                        <!-- MTOW -->
                        <div v-if="invoiceComposition.MTOW" class="invoice-total-item">
                          <p class="invoice-total-title">{{ invoiceComposition.MTOW.title }}&nbsp;:</p>
                          <p class="invoice-total-amount">
                            <span> {{ invoiceComposition.MTOW.value }} {{ $getMeasurementUnitItn('kg') }}</span>
                          </p>
                        </div>
                        <br>
                        <!-- Flight Type -->
                        <div v-if="invoiceComposition.flight_type" class="invoice-total-item">
                          <p class="invoice-total-title">{{ invoiceComposition.flight_type.title }}&nbsp;:</p>
                          <p class="invoice-total-amount">
                            <span> {{ invoiceComposition.flight_type.value }}</span>
                          </p>
                        </div>
                        <!-- Flight Mission -->
                        <div v-if="invoiceComposition.flight_mission" class="invoice-total-item">
                          <p class="invoice-total-title">{{ invoiceComposition.flight_mission.title }}&nbsp;:</p>
                          <p class="invoice-total-amount">
                            <span> {{ invoiceComposition.flight_mission.value }}</span>
                          </p>
                        </div>
                        <!-- Pax -->
                        <div v-if="invoiceComposition.pax_number" class="invoice-total-item">
                          <p class="invoice-total-title">{{ invoiceComposition.pax_number.title }}&nbsp;:</p>
                          <p class="invoice-total-amount">
                            <span> {{ invoiceComposition.pax_number.value }}</span>
                          </p>
                        </div>
                        <!-- Crew -->
                        <div v-if="invoiceComposition.crew_number" class="invoice-total-item">
                          <p class="invoice-total-title">{{ invoiceComposition.crew_number.title }}&nbsp;:</p>
                          <p class="invoice-total-amount">
                            <span> {{ invoiceComposition.crew_number.value }}</span>
                          </p>
                        </div>
                        <br>
                        <!-- Airport of service -->
                        <div v-if="invoiceComposition.airport_of_service" class="invoice-total-item">
                          <p class="invoice-total-title">{{ invoiceComposition.airport_of_service.title }}&nbsp;:</p>
                          <p class="invoice-total-amount">
                            <span> {{ invoiceComposition.airport_of_service.value }}</span>
                          </p>
                        </div>
                        <!-- From airport -->
                        <div v-if="invoiceComposition.airport_arrival_from" class="invoice-total-item">
                          <p class="invoice-total-title">{{ invoiceComposition.airport_arrival_from.title }}&nbsp;:</p>
                          <p class="invoice-total-amount">
                            <span> {{ invoiceComposition.airport_arrival_from.value }}</span>
                          </p>
                        </div>
                        <!-- To airport -->
                        <div v-if="invoiceComposition.airport_departure_to" class="invoice-total-item">
                          <p class="invoice-total-title">{{ invoiceComposition.airport_departure_to.title }}&nbsp;:</p>
                          <p class="invoice-total-amount">
                            <span> {{ invoiceComposition.airport_departure_to.value }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </section>

            <!-- Supply lines -->
            <section v-if="!headerOnly" class="pdf-item">
              <b-card-body :class="{ 'px-0': !printMode }">
                <!-- :no-price-increase="noPriceIncrease" -->
                <supply-document-table
                  :id="id"
                  :print-mode="printMode"
                  :supply-document-edition="edition"
                  :supply-document-lines="supply.lines"
                  :supply-document-loading="false"
                  @linesTouched="$emit('supplyTouched', $event)"
                  @updateLines="updateSupplyLines($event)"
                />
              </b-card-body>
            </section>

            <!-- Spacer -->
            <hr v-if="!headerOnly" class="invoice-spacing" />
            <div class="html2pdf__page-break" />

            <!-- Total -->
            <section v-if="!headerOnly" class="pdf-item">
              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row class="mb-2">
                  <!-- Col: Sales Persion -->
                  <b-col class="mt-md-0 mt-3" cols="12" md="6" order="2" order-md="1">
                    <b-card-text class="mb-0 mt-0 invoice-comment-wrapper">
                      <div v-if="supply.paymentMethodId" class="mb-50">
                        <span class="font-weight-bold mr-25">{{ `${$tc('payment.method.title', 1)} : ` }}</span>
                        <span>
                          {{ paymentMethodTypeById(supply.paymentMethodId) }}
                        </span>
                      </div>
                      <div v-if="supply.paymentMethodsTypes && supply.paymentTerm !== 'on_contract'" class="mb-50">
                        <span class="font-weight-bold mr-25">{{ `${$tc('payment.method.title', 3)} : ` }}</span>
                        <span v-for="methodType in supply.paymentMethodsTypes" :key="methodType">
                          {{ `${methodType},` }}
                        </span>
                      </div>
                      <div v-if="supply.paymentTerm" class="mb-50">
                        <span class="font-weight-bold mr-25">{{ `${$tc('payment.term.title', 1)} : ` }}</span>
                        <span class="card-text mt-25">
                          {{ supply.paymentTerm | enumTranslate('quote_payment_term') }}
                        </span>
                      </div>
                      <div v-if="supply.concretePaymentDate" class="mb-1">
                        <span class="font-weight-bold mr-25">{{ `${$t('payment.term.specific_terms')} : ` }} </span>
                        <p class="card-text mt-25">
                          {{
                            `${$t('payment.date.concrete_payment_at')} ${new Date(supply.concretePaymentDate).toLocaleDateString($i18n.locale, {
                              year: 'numeric',
                              month: 'short',
                              day: '2-digit',
                              weekday: 'short',
                            })} `
                          }}
                        </p>
                      </div>
                      <div v-if="supply.note">
                        <span class="font-weight-bold">{{ `${$t('common.comment')} : ` }}</span>
                        <p class="card-text mt-25">
                          {{ supply.note }}
                        </p>
                      </div>
                      <br>
                      <div
                        v-if="supply.paymentIntent && supply.paymentIntent.paymentMethod && supply.quotes && supply.quotes[0] && supply.quotes[0].paymentTerm !== 'on_contract'"
                        class="mb-50"
                      >
                        <span class="font-weight-bold mr-25">{{ `${$tc('payment.method.title', 0)} : ` }}</span>
                        <span>{{ supply.paymentIntent.paymentMethod.type | enumTranslate('payment_methods_type') }}</span>
                      </div>
                      <div v-if="supply.paymentIntent && supply.paymentIntent.captureDate" class="mb-50">
                        <span class="font-weight-bold mr-25">{{ $t('payment.captured_at') }} :</span>
                        <span class="card-text mt-25">
                          {{ $moment(supply.paymentIntent.captureDate).format('ddd, MMM Do YYYY HH:mm') }}
                        </span>
                      </div>
                      <div v-if="supply.paymentIntent && !supply.paymentIntent.captureDate && supply.paymentIntent.confirmDate" class="mb-50">
                        <span class="font-weight-bold mr-25">{{ $t('payment.payment_at') }} :</span>
                        <span class="card-text mt-25">
                          {{ $moment(supply.paymentIntent.confirmDate).format('ddd, MMM Do YYYY HH:mm') }}
                        </span>
                      </div>
                      <div v-if="supply.quotes && supply.quotes[0] && supply.quotes[0].paymentTerm" class="mb-50">
                        <span class="font-weight-bold mr-25">{{ `${$tc('payment.term.title', 1)} : ` }}</span>
                        <span class="card-text mt-25">
                          {{ supply.quotes[0].paymentTerm | enumTranslate('quote_payment_term') }}
                        </span>
                      </div>
                      <div
                        v-if="supply.quotes && supply.quotes[0] && supply.quotes[0].concretePaymentDate"
                        class="mb-50"
                      >
                        <span class="font-weight-bold mr-25">{{ `${$t('payment.term.specific_terms')} : ` }} </span>
                        <p class="card-text mt-25">
                          {{
                            `${$t('payment.date.concrete_payment_at')} ${new Date(supply.quotes[0].concretePaymentDate).toLocaleDateString($i18n.locale, {
                              year: 'numeric',
                              month: 'short',
                              day: '2-digit',
                              weekday: 'short',
                            })} `
                          }}
                        </p>
                      </div>
                      <div v-if="supply.quotes && supply.quotes[0] && supply.quotes[0].note" class="mb-50">
                        <span class="font-weight-bold">{{ `${$t('common.comment')} : ` }}</span>
                        <p class="card-text mt-25">
                          {{ supply.quotes[0].note }}
                        </p>
                      </div>
                      <div v-if="supply.client && supply.client.vatComment" class="mb-50">
                        <span class="font-weight-bold">{{ $t('common.note') }}</span>
                        <p class="card-text mt-25">
                          {{ supply.client.vatComment }}
                        </p>
                      </div>
                    </b-card-text>
                  </b-col>
                  <!-- Col: Total -->
                  <b-col class="mt-md-6 d-flex justify-content-end" cols="12" md="6" order="1" order-md="2">
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">{{ `${$tc('pricing.total_ex_tax_price', 0)} : ` }}</p>

                        <p
                          v-if="supply.priceExcludingTaxes !== null && supply.priceExcludingTaxes !== undefined"
                          class="invoice-total-amount"
                        >
                          <span> {{ supply.priceExcludingTaxes | priceFormat }}</span>
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">{{ `${$t('pricing.discount_rate')} : ` }}</p>

                        <p
                          v-if="supply.discountPrice !== null && supply.discountPrice !== undefined"
                          class="invoice-total-amount"
                        >
                          <span> {{ supply.discountPrice | priceFormat }}</span>
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">{{ `${$t('pricing.vat_rate')} : ` }}</p>

                        <p
                          v-if="supply.taxesPrice !== null && supply.taxesPrice !== undefined"
                          class="invoice-total-amount"
                        >
                          <span> {{ supply.taxesPrice | priceFormat }}</span>
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">{{ $tc('pricing.ecotax', 1) }} :</p>

                        <p
                          v-if="supply.ecotaxPrice !== null && supply.ecotaxPrice !== undefined"
                          class="invoice-total-amount"
                        >
                          <span>{{ supply.ecotaxPrice | priceFormat }}</span>
                        </p>
                      </div>
                      <hr class="my-50" />
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">{{ `${$tc('pricing.total_in_tax_price', 0)} : ` }}</p>

                        <p
                          v-if="supply.priceIncludingTaxes !== null && supply.priceIncludingTaxes !== undefined"
                          class="invoice-total-amount"
                        >
                          <span> {{ supply.priceIncludingTaxes | priceFormat }}</span>
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </section>
            <section v-if="!headerOnly && invoiceConfiguration && invoiceConfiguration.footer" class="pdf-item">
              <hr class="invoice-spacing" />
              <b-card-body class="invoice-padding text-center">
                <div>{{ invoiceConfiguration.footer }}</div>
              </b-card-body>
            </section>
          </b-card>
        </b-overlay>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SupplyDocumentTable from '@/components/SupplyDocumentTable.vue'

export default {
  name: 'SupplyDocument',

  components: {
    SupplyDocumentTable,
  },

  props: {
    id: {
      type: String,
      default: '',
    },

    edition: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '',
    },

    supply: {
      type: Object,
      default: null,
    },

    invoiceConfiguration: {
      type: Object,
      default: null,
    },

    marketplaceInvoice: {
      type: Boolean,
      default: false,
    },

    printMode: {
      type: Boolean,
      default: false,
    },

    headerOnly: {
      type: Boolean,
      default: false,
    },

    // noPriceIncrease: {
    //   type: Boolean,
    //   default: false,
    // },
  },

  data() {
    return {
      altOrganizationLogo: require('@/assets/images/icons/building.png'),
      // Demande 2946609744, pouvoir positionner la composition. Les noms des éléments doivent correspondre EXACTEMENT aux types du InvoiceCompositionItemType de l'API
      invoiceComposition: {
        aircraft_type: null,
        aircraft_model: null,
        call_sign: null,
        immatriculation: null,
        AOC: null,
        customer_vat_number: null,
        airport_of_service: null,
        MTOW: null,
        airport_arrival_from: null,
        airport_departure_to: null,
        pax_number: null,
        crew_number: null,
        flight_mission: null,
        flight_type: null,
      },
    }
  },

  computed: {
    ...mapGetters('paymentMethod', ['paymentMethodTypeById']),
  },

  watch: {
    invoiceConfiguration(newVal) {
      if (newVal.compositionItems) {
        newVal.compositionItems.forEach(compositionItem => {
          if (Object.hasOwn(this.invoiceComposition, compositionItem.invoiceCompositionItemType)) {
            this.invoiceComposition[compositionItem.invoiceCompositionItemType] = {
              title: this.$options.filters.enumTranslate(compositionItem.invoiceCompositionItemType, 'invoice_composition_item_type'),
              value: compositionItem.value,
            }
          }
        })
      }
    },
  },

  mounted() {
    if (this.supply?.paymentMethodId) this.fetchPaymentMethods()
  },
  methods: {
    ...mapActions('paymentMethod', ['fetchPaymentMethods']),

    updateSupplyLines(lines) {
      this.$emit('updateSupply', { ...this.supply, lines })
    },

    imageLoadOnError(e) {
      e.target.src = this.altOrganizationLogo
    },
    emptyObject(object) {
      return object && Object.values(object).every(o => o === null)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>

<style lang="scss" scoped>
.logo-dimension {
  max-height: 70px;
}

.supply-status {
  position: relative;
  overflow: hidden;

  &.supply-refused {
    &:after {
      content: 'REFUSED';
      color: #ea5455;
    }
  }

  &.supply-cancelled {
    &:after {
      content: 'CANCELLED';
      color: #ea5455;
    }
  }

  &:after {
    position: absolute;
    left: 20%;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 40%;
    height: 90%;
    width: 90%;
    text-align: center;
    font-weight: 900;
    font-size: 100px;
    opacity: 0.3;
    transform: rotate(335deg);
  }
}
</style>
